import React, { Component } from "react";
import { Link } from "react-router-dom";

import {
  userService,
  invalidFieldsMapper,
  payloadBuilder,
  networkErrorHelper,
} from "../../libs";
import { ROUTES } from "../../const";

import { MInput } from "components/forms/shared/MInput";
import { MnemoCallout, MSection, Title } from "components/misc";
import { Button } from "design-react-kit";

// tracciamento cookie statistici
import { getStatCookie } from "libs/helpers/cookieHelper";
import { MSelect } from "components/forms/shared/MSelect";

const FIELD_GROUPS = [
  [
    {
      field: "name",
      label: "Nome",
      type: "text",
      className: "form-group col-md-6",
    },
    {
      field: "surname",
      label: "Cognome",
      type: "text",
      className: "form-group col-md-6",
    },
  ],
  [
    {
      field: "email",
      label: "Email",
      type: "email",
      className: "form-group col-md-6",
    },
    {
      field: "emailRepeat",
      label: "Ripeti Email",
      type: "email",
      className: "form-group col-md-6",
    },
  ],
  [
    {
      field: "password",
      label: "Password",
      type: "password",
      className: "form-group col-md-6",
    },
    {
      field: "passwordRepeat",
      label: "Ripeti Password",
      type: "password",
      className: "form-group col-md-6",
    },
  ],
];

const validation = {
  name: (value) => value.length > 2,
  surname: (value) => value.length > 3,
  email: (value) => /\S+@\S+\.\S+/.test(value),
  emailRepeat: (value, { email }) => email && value === email.value,
  password: (value) => /(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/.test(value),
  passwordRepeat: (value, { password }) => password && value === password.value,
};

const errors = {
  name: "Inserire un Nome  valido",
  surname: "Inserire un Cognome valido",
  email: `L'Email non è valida`,
  emailRepeat: "Le due email non coincidono",
  password:
    "La Password non è valida (lunghezza minima 6 caratteri, almeno una maiuscola e un numero)",
  passwordRepeat: "Le due password non coincidono",
};

export default class Registration extends Component {
  emptyFields = {
    name: "",
    surname: "",
    email: "",
    emailRepeat: "",
    password: "",
    passwordRepeat: ""
  };

  state = {
    ...this.emptyFields,
    postRegistration: false,
    defaultValues: {
      ...this.emptyFields,
    },
  };

  constructor(props) {
    super(props);

    // bind event handler to invoke it from chiild component
    this.onChange = this.onChange.bind(this);
  }

  validateField(field, value) {
    const isValid = validation[field](value, this.state);
    return {
      value: value,
      isValid,
    };
  }

  onChange(field, value) {
    value = this.validateField(field, value);
    this.setState({ [field]: value });
  }

  canSubmit() {
    for (const field in this.state) {
      if (
        this.state[field] === null ||
        (this.state[field] instanceof Object &&
          this.state[field].isValid === false)
      )
        return false;
    }

    return true;
  }

  submit = (event) => {
    event.preventDefault();
    const payload = payloadBuilder(this.state);

    // set payload stat cookie;
    payload.statCookie = getStatCookie();

    userService
      .register(payload)
      .then(({ data }) => {
        //this.props.postRegistrationLogIn(data.payload);
        this.setState({ errors: {}, postRegistration: true });
      })
      .catch((errors) => {
        if (networkErrorHelper.is422(errors)) {
          const newState = invalidFieldsMapper(
            errors,
            this.state,
            Object.keys(validation)
          );
          this.setState({ ...newState });
        } else {
          networkErrorHelper.notify(errors);
        }
      });
  };

  renderForm() {
    return (
      <>
        {FIELD_GROUPS.map((fields, j) => (
          <div className="form-row" key={`rowfield-${j}`}>
            {fields.map(({ type, label, field, className, options }, i) => {
              const valid =
                this.state[field] instanceof Object && this.state[field].isValid
                  ? "Valid"
                  : "";
              const invalid =
                this.state[field] instanceof Object &&
                !this.state[field].isValid
                  ? "Invalid"
                  : "";
              const infoText = invalid ? errors[field] : "";
              let addAttr = {};
              if (valid) addAttr.valid = "true";
              if (invalid) addAttr.invalid = "true";

              return (
                <div className={className} key={`field-${i}`}>
                  {["text", "password", "email"].includes(type) && (
                    <MInput
                      id={field}
                      name={field}
                      type={type}
                      label={label}
                      infoText={infoText}
                      {...addAttr}
                      onChange={this.onChange}
                      onBlur={this.onChange}
                    />
                  )}

                  {["select"].includes(type) && (
                    <MSelect
                      id={field}
                      name={field}
                      label={label}
                      infoText={infoText}
                      payload={options}
                      {...addAttr}
                      onChange={this.onChange}
                      onBlur={this.onChange}
                    />
                  )}
                </div>
              );
            })}
          </div>
        ))}
      </>
    );
  }

  render() {
    const { postRegistration, email } = this.state;
    let code = <></>;

    if (postRegistration) {
      code = (
        <>
          <Title>Registrati</Title>
          <MnemoCallout>
            Grazie per esserti registrato. Ti abbiamo inviato una mail a{" "}
            {email.value} per confermare e completare la tua registrazione.
          </MnemoCallout>
        </>
      );
    } else {
      let form = this.renderForm();

      code = (
        <>
          <Title>Registrati</Title>
          <MSection>
            <p>
              Hai già un account? <Link to={ROUTES.LOGIN}>Accedi</Link>
            </p>
            <form className="signup" id="signup_form">
              {form}
              <hr />
              <p>
                Registrandoti, accetti la nostra{" "}
                <Link target="_blank" to={ROUTES.PRIVACY_POLICY}>
                  privacy policy
                </Link>
                .
              </p>
              <div className="py-4">
                <Button
                  color="primary bg-dark"
                  onClick={this.submit}
                  disabled={!this.canSubmit()}>
                  Registrati
                </Button>
              </div>
            </form>
          </MSection>
        </>
      );
    }

    return code;
  }
}
