import React, { Component } from "react";
import { adminService, dateHelper, networkErrorHelper } from "libs";
import { MTable } from "components/table";
import { Container } from "design-react-kit";
import { BoxDanger } from "components/misc";

import { MnemoLoading, ModalHandler } from "components/misc";

import { NavLink } from "react-router-dom";
import { RC30_EDITION, ROUTES } from "const";
import { MInput } from "components/forms";

//import "./CourseManager.css";

class LessonLiveSchedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      payload: [],
      payloadToRender: [],
      searchFilter: {
        searchCC: "",
        searchAA: "",
      },
      rndKey: 0,
      searchFieldCC: [
        {
          id: "searchCC",
          label: "cerca per classe di concorso",
          type: "text",
          className: "form-control",
          onKeyUp: (elm, value) => {
            this.onSearchForm(value);
          },
          //value: "",
        },
        {
          id: "searchFrom",
          field: "searchFrom",
          label: "dal",
          type: "date",
          className: "form-control",
          onChange: (elm, value) => {
            this.onSearchForm(value);
          },
          value: "",
        },
        {
          id: "searchTo",
          field: "searchTo",
          label: "al",
          type: "date",
          className: "form-control",
          onChange: (elm, value) => {
            this.onSearchForm(value);
          },
          value: "",
        },
      ],
    };
  }

  //--- ---
  tableConfig = {
    columns: [
      {
        id: "id", // identifier of columns
        Header: "Column 0",
        isVisible: false, // IMPORTANT: hidden columns
        accessor: "id", // accessor is the "key" in the data
      },
      {
        id: "title",
        Header: "Titolo",
        accessor: "title",
      },
      {
        id: "date",
        Header: "Data",
        accessor: (row) => {
          return row.date ? dateHelper.toITDateUTC(row.date) : "";
        },
      },
      {
        id: "hourStart",
        Header: "Ora inizio",
        accessor: (row) => {
          return row.hourStart
            ? row.hourStart.substring(0, row.hourStart.length - 3)
            : "";
        },
      },
      {
        id: "teacher",
        Header: "Docente",
        accessor: "teacher",
      },
      {
        id: "ssd",
        Header: "Materia",
        accessor: "ssd",
      },
      {
        id: "competitionClass",
        Header: "Classe di concorso",
        accessor: "competitionClass",
      },
      {
        id: "maxMember",
        Header: "Capienza",
        accessor: "maxMember",
      },
      {
        id: "classGroup",
        Header: "Aula",
        accessor: "classGroup",
      },
      {
        id: "idExamSession",
        Header: "Edizione",
        accessor: (row) => {
          return this.getEdition(row);
        },
      },
      {
        id: "enableAttendance",
        Header: "Attestato",
        accessor: (row) => {
          return row.enableAttendance ? "SI" : "NO";
        },
      },
    ],
    actions: [
      {
        id: "detail",
        accessor: "id",
        label: "Dettagli",
        icon: "it-file",
        className: "MTable-actions",
        onClick: (e, value) => {
          window.location.href = ROUTES.LESSON_LIVE_SCHEDULE_FORM + "/" + value;
        },
      },
      {
        id: "analytics",
        accessor: "id",
        label: "Analytis",
        icon: "it-chart-line",
        className: "MTable-actions",
        onClick: (e, value) => {
          window.location.href =
            ROUTES.LESSON_LIVE_SCHEDULE_ANALYTICS + "/" + value;
        },
      },
      {
        id: "delete",
        accessor: "id", // field value received in the onclick event
        label: "Elimina",
        icon: "it-delete",
        className: "MTable-actions",
        onClick: (e, value) => {
          this.deleteRecord(e, value);
        },
      },
    ],
  };
  //--- ---

  getEdition(row) {
    let edition = "TUTTI";

    switch (row.idExamSession) {
      case RC30_EDITION.first:
        edition = "PRIMA";
        break;

      case RC30_EDITION.second:
        edition = "SECONDA";
        break;

      case RC30_EDITION.third:
        edition = "TERZA";
        break;

      default:
        edition = "TUTTI";
        break;
    }

    return edition;
  }

  componentDidMount() {
    this.loadLessonLiveSchedule();
  }

  loadLessonLiveSchedule() {
    adminService
      .loadLessonLiveSchedule({ searchFilter: {} })
      .then(({ data }) => {
        //--- start: prepare reactTable ---
        const items = [];
        Object.entries(data.payload.lessonLiveSchedule).forEach(
          ([key, val]) => {
            items.push(val);
          }
        );
        //--- end: prepare reactTable ---

        this.setState({
          loading: false,
          payload: items,
          payloadToRender: items,
          rndKey: Math.floor(Math.random() * 1000 + 1),
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        } else {
          this.setState({
            payload: [],
            rndKey: Math.floor(Math.random() * 1000 + 1),
          });
        }
      });
  }

  //--- start functions to delete procedure ---
  deleteRecord(e, value) {
    ModalHandler.show(
      value,
      "Eliminazione definitiva del dato",
      "vuoi davvero eliminare?",
      null,
      this.deletePermanentlyConfirmed
    );
  }

  deletePermanentlyConfirmed = (value) => {
    adminService
      .deleteLessonLiveSchedule(value)
      .then(({ data }) => {
        this.setState({ loading: true });
        this.loadLessonLiveSchedule(this.state.searchFilter);
      })
      .catch((error) => {
        console.log(error);
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        }
      });
  };
  //--- end functions to delete procedure ---

  /*onSearchCC = (keySearch) => {
    if (
      keySearch.length < 3 &&
      this.state.payloadToRender !== this.state.payload
    ) {
      this.setState({
        payloadToRender: this.state.payload,
        rndKey: Math.floor(Math.random() * 1000 + 1),
      });
      return false;
    }

    if (keySearch.length < 3) return false;

    const results = this.state.payload.filter(function (item) {
      return item.competitionClass
        .toLowerCase()
        .includes(keySearch.toLowerCase());
    });

    this.setState({
      payloadToRender: results,
      rndKey: Math.floor(Math.random() * 1000 + 1),
    });
  }; //onSearch*/

  onSearchForm = () => {
    const searchCC = document.getElementById("searchCC").value.trim();
    const searchFrom = document.getElementById("searchFrom").value.trim();
    const searchTo = document.getElementById("searchTo").value.trim();
    
    let searchFieldCCUpdated = [...this.state.searchFieldCC]
    //searchFieldCCUpdated[0].value = searchCC;
    searchFieldCCUpdated[1].value = searchFrom;
    searchFieldCCUpdated[2].value = searchTo;

    this.setState({
      ...this.state,
      searchFieldCC: searchFieldCCUpdated
    });

    const searchFromDate = searchFrom
      ? new Date(searchFrom + "T00:00:00")
      : null;
    const searchToDate = searchTo ? new Date(searchTo + "T23:59:59") : null;

    const results = this.state.payload.filter((item) => {
      let match = true;

      if (searchCC !== "") {
        match =
          match &&
          item.competitionClass.toLowerCase().includes(searchCC.toLowerCase());
      }

      const itemDate = new Date(item.date);

      if (searchFromDate && !isNaN(searchFromDate.getTime())) {
        match = match && itemDate >= searchFromDate;
      }

      if (searchToDate && !isNaN(searchToDate.getTime())) {
        match = match && itemDate <= searchToDate;
      }

      return match;
    });

    this.setState({
      payloadToRender: results,
      rndKey: Math.floor(Math.random() * 1000 + 1),
    });
  }; //onSearchForm

  //--- ---

  render() {
    const { loading } = this.state;

    return (
      <>
        <div className="container mt-3 mb-4">
          <section className="m-0">
            <div className="form-row mb-4">
              <div className="col-md-4">
                <MInput {...this.state.searchFieldCC[0]} className="mb-0" />
              </div>
              <div className="col-md-4">
                <MInput {...this.state.searchFieldCC[1]} className="mb-0" />
              </div>
              <div className="col-md-4">
                <MInput {...this.state.searchFieldCC[2]} className="mb-0" />
              </div>
            </div>

            <div className="form-row">
              <div className="col-md-12 text-right">
                <NavLink
                  to={ROUTES.LESSON_LIVE_PROCESS_ANALYTICS}
                  className="btn btn-danger mx-1">
                  Analisi Sede esame
                </NavLink>
                <NavLink
                  to={ROUTES.LESSON_LIVE_CALENDAR_WEBEX}
                  className="btn btn-primary bg-dark mx-1">
                  Calendario Aule Webex
                </NavLink>
                <NavLink
                  to={ROUTES.LESSON_LIVE_EDITION}
                  className="btn btn-primary bg-dark mx-1">
                  Gestione Edizioni
                </NavLink>
                <NavLink
                  to={ROUTES.LESSON_LIVE_CLASS}
                  className="btn btn-primary bg-dark mx-1">
                  Gestione Aule
                </NavLink>
                <NavLink
                  to={ROUTES.LESSON_LIVE_SCHEDULE_FORM}
                  className="btn btn-primary bg-dark mx-1">
                  Nuova Lezione
                </NavLink>
              </div>
            </div>
          </section>

          <hr />

          <section className="my-4">
            {loading ? (
              <MnemoLoading></MnemoLoading>
            ) : (
              <Container>
                <MTable
                  key={this.state.rndKey}
                  tableData={this.state.payloadToRender}
                  tableConfig={this.tableConfig}
                />
              </Container>
            )}

            {this.state.payloadToRender.length === 0 && (
              <BoxDanger key="nrp-1" className="my-3">
                Ricerca non valida.
                <div className="small text-info">Nessun dato disponibile.</div>
              </BoxDanger>
            )}
          </section>
        </div>
      </>
    );
  }
}

export default LessonLiveSchedule;
