import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

import {
  MnemoLoading,
  BoxDanger,
  Title,
  MSection,
  SupportoTecnico,
} from "components/misc";
import { courseService, learningService, networkErrorHelper } from "libs";

import { Row, Col, Icon } from "design-react-kit";
import {
  SidebarLearning,
  LessonPage,
  LessonFile,
  LessonVideo,
  FooterLessonIndex,
} from "components/learning";
import { ROUTES } from "const";

class Lesson extends Component {
  state = {
    id: 0,
    lesson: 0,
    resource: 0,
    file: 0,
    page: 0,
    loading: true,
    loadingError: false,
    firstLesson: 0,
    rCode: null,
    moduleTitle: "",
    parentTitle: "",
    maintenanceSection: "",
  };

  componentDidMount() {
    const { id, rCode, idModule } = this.props.match.params;
    this.setState({
      currentID: 0,
      id: id,
      rCode: rCode,
      idModule: idModule,
    });

    this.loadRemote(id, idModule);
  }

  //UNSAFE_componentWillReceiveProps
  componentDidUpdate(prevProps, prevState) {
    const { id, rCode, idModule } = this.props.match.params;

    if (this.state.id !== id) {
      this.setState(
        {
          currentID: 0,
          id: id,
          rCode: rCode,
          idModule: idModule,
        },
        () => {
          this.loadRemote(id, idModule);
        }
      );
    }
  }

  loadRemote(id, idModule = 0) {
    this.loadLesson(id);

    //--- ---
    if (parseInt(idModule) > 0) {
      courseService.loadSection(idModule).then(({ data }) => {
        this.setState({
          moduleTitle: data.payload.section.title,
          maintenanceSection: data.payload.section.maintenance,
        });
      });
    } //parseInt(idModule) > 0
  }

  loadLesson(id) {
    learningService
      .lesson(id)
      .then(({ data }) => {
        learningService
          .loadLesson(data.payload.lesson.idParent)
          .then(({ data }) => {
            this.setState({
              parentTitle: data.payload.lesson.label,
            });
          })
          .catch((error) => {
            console.log(error);
          });

        const lesson = Object.assign({}, data.payload.lesson);
        this.setState({
          lesson,
          loading: false,
        });
      })
      .catch((error) => {
        if (!networkErrorHelper.is404(error)) {
          networkErrorHelper.notify(error);
        }

        this.setState({ loadingError: true, loading: false });
      });
  }

  //--- lessons index ---
  renderLessonsIndex() {
    const lesson = this.state.lesson;
    const level = lesson.level;
    const resourceType = this.state.lesson.resource.type;

    const SideBarLessonIndex = lesson.lessonsIndex.filter(function (dataset) {
      let idxLevel = resourceType === "marker" ? level + 1 : level;
      return dataset.level === idxLevel;
    });

    return (
      <FooterLessonIndex
        lessonsIndex={SideBarLessonIndex}
        rCode={this.state.rCode}
        idModule={this.state.idModule}
      />
    );
  } //lessonsIndex

  renderSidebar() {
    const lesson = this.state.lesson;
    const level = lesson.level;
    const resourceType = this.state.lesson.resource.type;

    const SideBarLessonIndex = lesson.lessonsIndex.filter(function (dataset) {
      let idxLevel = resourceType === "marker" ? level + 1 : level;
      return dataset.level === idxLevel;
    });

    return (
      <SidebarLearning
        lessonsIndex={SideBarLessonIndex}
        rCode={this.state.rCode}
        idModule={this.state.idModule}
      />
    );
  } //lessonsIndex

  render() {
    const { loading, loadingError, lesson, maintenanceSection } = this.state;
    if (loading) return <MnemoLoading></MnemoLoading>;

    //--- Content ---

    const resourceTypeComponent = (resourceType) => {
      /*if (resourceType === 'marker') {
                return <LessonPage lesson={lesson.resource} />
            }*/

      if (resourceType === "page") {
        return <LessonPage lesson={lesson.resource} />;
      }

      if (resourceType === "file") {
        return <LessonFile lesson={lesson.resource} />;
      }

      if (resourceType === "video") {
        return <LessonVideo lesson={lesson.resource} />;
      }
    }; //resourceTypeComponent

    const subtitle =
      this.state.moduleTitle !== "" &&
      `${this.state.moduleTitle} - ${this.state.parentTitle}`;
    const lessonsIndex = this.renderLessonsIndex();
    const lessonsSidebar = this.renderSidebar();

    return (
      <>
        {loadingError ? (
          <BoxDanger className="mt-4">
            Si è verificato un errore durante il caricamento della lezione, se
            il problema persiste puoi
            <br />
            <SupportoTecnico />
          </BoxDanger>
        ) : maintenanceSection !== "" && maintenanceSection !== null ? (
          <BoxDanger className="mt-4">{maintenanceSection}</BoxDanger>
        ) : (
          <>
            <Title subtitle={subtitle}>
              {" "}
              {ReactHtmlParser(lesson.resource.title)}{" "}
            </Title>
            <Row className="d-block d-lg-none">
              <MSection>
                <NavLink
                  to={`${ROUTES.COURSE_DASHBOARD}/${this.state.rCode}`}
                  className="btn btn-outline-primary">
                  <Icon icon="it-presentation" />
                  HOME PAGE DEL CORSO
                </NavLink>
                <Icon icon="it-arrow-right" className="m-1" />
                <NavLink
                  to={`${ROUTES.LEARNING_MAIN}/${this.state.rCode}`}
                  className="btn btn-outline-primary">
                  <Icon icon="it-copy" className="m-1" />
                  INDICE DEL CORSO
                </NavLink>
              </MSection>
            </Row>
            <Row>
              <Col lg={9} md={12}>
                <MSection>
                  {resourceTypeComponent(this.state.lesson.resource.type)}
                </MSection>
              </Col>
              <Col lg={3} className="bd-toc d-none d-lg-block">
                {lessonsSidebar}
              </Col>
            </Row>
            <Row className="d-block d-lg-none">
              <MSection>{lessonsIndex}</MSection>
            </Row>
          </>
        )}
      </>
    );
  }
}

export default withRouter(Lesson);
