import { CM30_EDITION, ROUTES } from "const";
import { courseService, learningLiveService } from "libs";

export class CM30 {
  // SAVERIO: PEZZA per mostrare avvisi differenti per edizioni differenti
  static secondEdition = CM30_EDITION.second;

  static async getDashboardCards(registrationCode, idExamSession) {
    let cards = this.getFixedCards(idExamSession);

    try {
      const remoteResult = await learningLiveService.isOpenClassBooking(
        registrationCode
      );
      if (
        remoteResult &&
        remoteResult.data &&
        remoteResult.data.payload &&
        remoteResult.data.payload.length > 0
      ) {
        cards.push({
          title: "Assegnazione Aula",
          link: ROUTES.COURSE_BOOKING_CLASS,
          img: "/img/icon/student.png",
          className: "image-box",
        });
      }

      // verify dynamic links
      const result = await courseService.perfezionamentoActiveLink(
        registrationCode
      );
      if (result.data.payload) {
        const { payload } = result.data;

        if (payload.examReservationEnabled === "1") {
          cards.push({
            title: "Prenotazione Esami",
            link: ROUTES.COURSE_RC30_EXAM_RESERVATION,
            img: "/img/icon/badge.png",
            className: "image-box",
          });
        }

        if (payload.enableExamSubject === "1") {
          cards.push({
            title: "Argomenti Esami",
            link: ROUTES.COURSE_RC30_EXAM_SUBJECT,
            img: "/img/icon/7.png",
            className: "image-box",
          });
        }

        if (payload.certRequestEnabled === "1") {
          cards.push({
            title: "Consultazione Esito Finale",
            link: ROUTES.COURSE_RC30_CERT_REQUEST,
            img: "/img/icon/1.png",
            className: "image-box",
          });
        }

        if (payload.activeSurvey && payload.activeSurvey.length > 0) {
          cards.push({
            title: "Indagine " + payload.activeSurvey,
            link: ROUTES.COURSE_SURVEY_MAIN + "/" + payload.activeSurvey,
            img: "/img/icon/call.png",
            className: "image-box",
          });
        }

        if (payload.activeSurvey && payload.activeSurvey.length > 0) {
          cards.push({
            title: "Indagine " + payload.activeSurvey,
            link: ROUTES.COURSE_SURVEY_MAIN + "/" + payload.activeSurvey,
            img: "/img/icon/call.png",
            className: "image-box",
          });
        }
      }
    } catch (error) { }

    return cards;
  }

  static getFixedCards(idExamSession) {
    let cards = [
      {
        title: "Messaggi",
        link: ROUTES.COURSE_TICKETS,
        img: "/img/icon/message.png",
        className: "image-box",
      },
      {
        title: "Situazione pagamenti",
        link: ROUTES.COURSE_PAYMENTS,
        img: "/img/icon/payment.png",
        className: "image-box",
      },
      {
        title: "Lezioni",
        link: ROUTES.COURSE_LESSONLIVE_SCHEDULE_ONLINE,
        img: "/img/icon/presentation.png",
        className: "image-box",
      },
    ];

    if (idExamSession === this.secondEdition) {
      cards.push({

        title:
          "Conferma o modifica la sede d'esame",
        link: ROUTES.COURSE_RC30_CONFIRM_EXAMSITE,
        img: "/img/icon/geo.png",
        className: "image-box",
      });
    }

    return cards;
  }

  static getDashboardNotices(idExamSession) {
    if (idExamSession === this.secondEdition) {
      return [
        {
          title:
            "Avviso Lezione del 27 Settembre 2024 inerente la Seconda fase del percorso",
          content: `<p><a target="_blank" href="https://www.formazionedocenti.it/files/corsi/CM30/avviso-lezione-27-settembre-cm30.pdf">Clicca qui per scaricare l'informativa</a></p>`,
        },
        {
          title: "Linee guida svolgimento delle lezioni",
          content: `<p><a href="https://www.formazionedocenti.it/files/corsi/RC30/linee-guida-lezioni-30cfu-generic.pdf">Clicca qui per scaricare le linee guida</a></p>`,
        },
        {
          title: `Calendario Corsi Abilitanti 30 CFU (ex art.13 DPCM 4 agosto 2023)`,
          content: `Prima fase del percorso - <a href="https://www.formazionedocenti.it/files/corsi/CM30/cm30-calendario-lezioni-edizione2-fase1.pdf" target="_blank">Clicca qui per scaricare l'informativa</a>`,
        },
      ];
    }
  }
}
