import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/it";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Rome");

export const dateHelper = {
  toITDate(date) {
    let result = "";
    if (date !== "" && date !== null) {
      result = dayjs(date).format("DD/MM/YYYY");
    }

    return result;
  },

  toITDateUTC(date) {
    let result = "";
    if (date !== "" && date !== null) {
      result = dayjs.utc(date).format("DD/MM/YYYY");
    }

    return result;
  },

  toITDayUTC(date) {
    let result = "";
    if (date !== "" && date !== null) {
      result = dayjs.utc(date).locale("it").format("dddd");
    }

    return result;
  },


  toITDay(date) {
    let result = "";
    if (date !== "" && date !== null) {
      result = dayjs(date).locale("it").format("dddd");
    }

    return result;
  },

  toITDateTime(date) {
    return dayjs(date).format("DD/MM/YYYY H:mm");
  },

  toShortTime(date) {
    // remove last 3 characters form the string
    return date.slice(0, -3);
  },

  toITTime(date) {
    return dayjs(date).format("ore H:mm:s");
  },

  toITTimeNoSec(date) {
    return dayjs(date).format("ore H:mm");
  },

  toUSDate(date) {
    let result = "";
    if (date !== "" && date !== null) {
      result = dayjs(date).format("YYYY-MM-DD");
    }

    return result;
  },

  toUSDateTime(date) {
    return dayjs(date).format("YYYY-MM-DD H:m:s");
  },

  toUSTime(date) {
    return dayjs(date).format("H:m:s");
  },

  secondsToTime(value) {
    const sec = parseInt(value, 10);
    let hours = Math.floor(sec / 3600);
    let minutes = Math.floor((sec - hours * 3600) / 60);
    //let seconds = sec - (hours * 3600) - (minutes * 60);

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    //if (seconds < 10) {seconds = "0"+seconds;}

    return `${hours} ore ${minutes} min.`;
  },

  timeInTheFuture(time) {
    let result = false;
    if (time) {
      const fromtime = time;
      const totime = dayjs().format("HH:mm");

      const ft = dayjs(`2000-01-01 ${fromtime}`);
      const tt = dayjs(`2000-01-01 ${totime}`);
      const mins = tt.diff(ft, "minutes", true);
      //console.log(mins);
      result = mins > 0;
    }

    return result;
  },

  noFuture(value) {
    let res = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/.test(value);
    if (res && dayjs(value).isAfter(dayjs())) {
      res = false;
    }
    return res;
  },
};
