import {proxyBE} from './common';

export const enrollmentService = {    
        
    sendEmailToMultipleEnroll(payload){
        return proxyBE.post('enrollment/sendemail-requestmultienroll', payload);
    },   
    
    checkEnrollAbilitation(courseCode, userId){
        return proxyBE.get('enrollment/check-enrollabilitation/'+courseCode+'/'+userId);
    },

    startEnrollment(payload){
        return proxyBE.post('enrollment/start/', payload);
    },

    endEnrollment(payload){
        return proxyBE.post('enrollment/end/', payload);
    },
    
    endUnicamillus(payload){
        return proxyBE.post('enrollment/end-unicamillus/', payload);
    },

    prepareSign(enrollmentId){
        return proxyBE.put('enrollment/prepare-sign', {enrollmentId});
    },

    myEnrollment(courseCode, currentAA){
        return proxyBE.get(`enrollment/my/${courseCode}/${currentAA}`);
    },

    myPendingDetail(orderNumber){
        return proxyBE.get(`enrollment/pending-detail/${orderNumber}`);
    },

    status(orderNumber){
        return proxyBE.get(`enrollment/status/${orderNumber}`);
    },

    myPending(){
        return proxyBE.get('enrollment/pending');
    },

    updatePayment(payload){
        return proxyBE.put('enrollment/update-payment', payload);        
    },

    getPayment(orderNumber){
        return proxyBE.get(`payment-enrollment/${orderNumber}`);
    },

    updateEnrollment(payload){
        return proxyBE.put('enrollment/update', payload);        
    },

    updateEnrollmentQualification(payload){
        return proxyBE.put('enrollment/enrollmentQualification', payload);        
    },

    updateEnrollmentConfirmExamSite(payload){
        return proxyBE.put('enrollment/enrollmentConfirmExamSite', payload);        
    },
   
    enrollmentByID(idEnrollment) {
        return proxyBE.get(`enrollment/detail/${idEnrollment}`);        
    },

    enrollmentDelete(payload){
        return proxyBE.put('enrollment/delete', payload);        
    },

    enrollmentReset(payload){
        return proxyBE.put('enrollment/reset', payload);        
    },
}