import React from "react";
import ReactHtmlParser from "react-html-parser";
import { NavLink } from "react-router-dom";
import {
  BoxDanger,
  BoxSuccess,
  BoxWarning,
  SupportoTecnico,
} from "components/misc";
import { PAYMENT_TYPES, ROUTES } from "const";
import {
  userService,
  adminService,
  enrollmentService,
  networkErrorHelper,
  payloadBuilder,
  invalidFieldsMapper,
  enrollmentAdminService,
  CourseHelper,
} from "libs";
import { creditCardHelper } from "libs/services";
import {
  MCheckbox,
  MInput,
  MSelect,
  MButton,
  ValidatedForm,
} from "components/forms";
import {
  FormCS24,
  FormENG,
  FormATA,
  FormINFO,
  FormCV,
  FormUCSU,
  FormRCSU,
  FormUCSUCombo,
  FormRC30CFU,
  FormUM30CFU,
  FormTFA,
  FormTFAOrdinario,
  FormRCCFUALL,
  FormRCSUCombo,
  FormCM30CFU,
} from "./";

import {
  Button,
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "design-react-kit";

// tracciamento cookie statistici
import { getStatCookie } from "libs/helpers/cookieHelper";
import { getCurrentUser } from "store/actions";

export class CourseShopper extends ValidatedForm {
  _pluginRef = null;

  constructor(props) {
    super(props);

    this._pluginRef = React.createRef();
    this.applyPromo = this.applyPromo.bind(this);
    this.validateApplyButton = this.validateApplyButton.bind(this);
  }

  emptyFields = {
    importToPay: -1,
    isPaymentReferred: false,
    paymentType: "",
    officeCode: "",
    name: "",
    surname: "",
    emailLogin: "",
    passwordLogin: "",
    email: "",
    password: "",
    accept1: false,
    accept2: false,
    hearAboutUs: "",
    //  canEnroll: true
  };

  defaultState = {
    collapseOpen1: false,
    collapseOpen2: false,
  };

  state = {
    showEnrollResult: false,
    toLogin: false,
    toRegister: false,
    courseCode: 0,
    isUnicamillus: false,
    isReggio: false,
    isTchaikovsky: false,
    enrollment: null,
    loading: true,
    loadingError: false,
    ...this.emptyFields,
    ...this.defaultState,
    alertMessages: [],
    defaultValues: {
      ...this.emptyFields,
    },
    promoAmount: 0,
    isProvider: false,
    onlyReferred: false,
    skipFirstRate: false,
    PAYLOADS: [],
    showDetailPayment: false,
    importWithCartaDocente: 0,
    multipleEnrollAllow: false,
    courseEnrolled: null,
    userId: null,
    multipleEnrollSendEmail: false,
  };

  PAYLOADS = {
    importToPay: [{ label: "Seleziona importo", value: "-1" }],
    paymentTypes: [
      { value: "", label: "Metodo di pagamento" },
      { value: PAYMENT_TYPES.CARTA_DI_CREDITO, label: "Carta di credito" },
      { value: PAYMENT_TYPES.BONIFICO, label: "Bonifico" },
      { value: PAYMENT_TYPES.CARTA_DEL_DOCENTE, label: "Carta del docente" },
    ],
    paymentTypesUnicamillus: [
      { value: "", label: "Metodo di pagamento" },
      {
        value: PAYMENT_TYPES.PAGOPA,
        label: "PagoPA (Bonifico, Carta di credito, bollettino)",
      },
      { value: PAYMENT_TYPES.CARTA_DEL_DOCENTE, label: "Carta del docente" },
    ],
    paymentTypesTchaikovsky: [
      { value: "", label: "Metodo di pagamento" },
      {
        value: PAYMENT_TYPES.PAGOPA,
        label: "PagoPA (Bonifico, Carta di credito, bollettino)",
      },
    ],
    paymentTypesReggio: [
      { value: "", label: "Metodo di pagamento" },
      {
        value: PAYMENT_TYPES.MAV,
        label: "MAV",
      },
    ],
    paymentTypesReggioBonifico: [
      { value: "", label: "Metodo di pagamento" },
      { value: PAYMENT_TYPES.BONIFICO, label: "Bonifico" },
    ],
    heardAboutUs: [
      { label: "Seleziona", value: "0" },
      { label: "Social Network", value: "socialnetwork" },
      { label: "TV", value: "tv" },
      { label: "Passaparola", value: "passaparola" },
      { label: "Motori di ricerca", value: "motori-di-ricerca" },
      { label: "Orizzonte Scuola", value: "orizzontescuola" },
      { label: "Radio", value: "radio" },
      { label: "Cartelloni pubblicitari", value: "cartelloni-pubblicitari" },
      { label: "Eventi/Fiere", value: "eventi-fiere" },
      { label: "Altro", value: "altro" },
    ],
    isPaymentReferred: false,
  };

  //---

  FIELDS_ENROLL_UNICAMILLUS = [
    [
      {
        field: "officeCode",
        label: "Codice Riservato",
        autoComplete: "off",
        component: MInput,
        type: "text",
        className: "col-md-6",
        onChange: (name, value) => {
          this.validateApplyButton(value);
        },
      },
      {
        id: "applyPromoBtn",
        label: "Applica",
        infoText:
          'Per convalidare il codice riservato digitato cliccare su "Applica"',
        component: MButton,
        className: "col-md-6",
        classElement: "btn btn-primary bg-dark",
        disabled: true,
        onClick: () => {
          this.applyPromo(this.state.officeCode.value.toUpperCase());
        },
      },
    ],
    [
      {
        field: "importToPay",
        label: "Importo da pagare",
        payload: { options: this.PAYLOADS.importToPay },
        component: MSelect,
        onChange: (_, value) => this.onChangePayment(_, value),
        className: "col-md-6",
      },
      {
        field: "paymentType",
        label: "Metodo di pagamento",
        payload: { options: this.PAYLOADS.paymentTypesUnicamillus },
        onChange: (_, value) => this.onChangePaymentType(_, value),
        component: MSelect,
        className: "col-md-6",
      },
    ],
  ];

  FIELDS_ENROLL_TCHAIKOVSKY = [
    [
      {
        field: "officeCode",
        label: "Codice Riservato",
        autoComplete: "off",
        component: MInput,
        type: "text",
        className: "col-md-6",
        onChange: (name, value) => {
          this.validateApplyButton(value);
        },
      },
      {
        id: "applyPromoBtn",
        label: "Applica",
        infoText:
          'Per convalidare il codice riservato digitato cliccare su "Applica"',
        component: MButton,
        className: "col-md-6",
        classElement: "btn btn-primary bg-dark",
        disabled: true,
        onClick: () => {
          this.applyPromo(this.state.officeCode.value.toUpperCase());
        },
      },
    ],
    [
      {
        field: "importToPay",
        label: "Importo da pagare",
        payload: { options: this.PAYLOADS.importToPay },
        component: MSelect,
        onChange: (_, value) => this.onChangePayment(_, value),
        className: "col-md-6",
      },
      {
        field: "paymentType",
        label: "Metodo di pagamento",
        payload: { options: this.PAYLOADS.paymentTypesTchaikovsky },
        onChange: (_, value) => this.onChangePaymentType(_, value),
        component: MSelect,
        className: "col-md-6",
      },
    ],
  ];

  FIELDS_ENROLL_REGGIO = [
    [
      {
        field: "officeCode",
        label: "Codice Riservato",
        autoComplete: "off",
        component: MInput,
        type: "text",
        className: "col-md-6",
        onChange: (name, value) => {
          this.validateApplyButton(value);
        },
      },
      {
        id: "applyPromoBtn",
        label: "Applica",
        infoText:
          'Per convalidare il codice riservato digitato cliccare su "Applica"',
        component: MButton,
        className: "col-md-6",
        classElement: "btn btn-primary bg-dark",
        disabled: true,
        onClick: () => {
          this.applyPromo(this.state.officeCode.value.toUpperCase());
        },
      },
    ],
    [
      {
        field: "importToPay",
        label: "Importo da pagare",
        payload: { options: this.PAYLOADS.importToPay },
        component: MSelect,
        onChange: (_, value) => this.onChangePayment(_, value),
        className: "col-md-6",
      },
      {
        field: "paymentType",
        label: "Metodo di pagamento",
        payload: { options: this.PAYLOADS.paymentTypesReggio },
        onChange: (_, value) => this.onChangePaymentType(_, value),
        component: MSelect,
        className: "col-md-6",
      },
    ],
  ];

  FIELDS_ENROLL_REGGIO_BONIFICO = [
    [
      {
        field: "officeCode",
        label: "Codice Riservato",
        autoComplete: "off",
        component: MInput,
        type: "text",
        className: "col-md-6",
        onChange: (name, value) => {
          this.validateApplyButton(value);
        },
      },
      {
        id: "applyPromoBtn",
        label: "Applica",
        infoText:
          'Per convalidare il codice riservato digitato cliccare su "Applica"',
        component: MButton,
        className: "col-md-6",
        classElement: "btn btn-primary bg-dark",
        disabled: true,
        onClick: () => {
          this.applyPromo(this.state.officeCode.value.toUpperCase());
        },
      },
    ],
    [
      {
        field: "importToPay",
        label: "Importo da pagare",
        payload: { options: this.PAYLOADS.importToPay },
        component: MSelect,
        onChange: (_, value) => this.onChangePayment(_, value),
        className: "col-md-6",
      },
      {
        field: "paymentType",
        label: "Metodo di pagamento",
        payload: { options: this.PAYLOADS.paymentTypesReggioBonifico },
        onChange: (_, value) => this.onChangePaymentType(_, value),
        component: MSelect,
        className: "col-md-6",
      },
    ],
  ];

  FIELDS_ENROLL = [
    [
      {
        field: "officeCode",
        label: "Codice Riservato",
        autoComplete: "off",
        component: MInput,
        type: "text",
        className: "col-md-6",
        onChange: (name, value) => {
          this.validateApplyButton(value);
        },
      },
      {
        id: "applyPromoBtn",
        label: "Applica",
        infoText:
          'Per convalidare il codice riservato digitato cliccare su "Applica"',
        component: MButton,
        className: "col-md-6",
        classElement: "btn btn-primary bg-dark",
        disabled: true,
        onClick: () => {
          this.applyPromo(this.state.officeCode.value.toUpperCase());
        },
      },
    ],
    [
      {
        field: "importToPay",
        label: "Importo da pagare",
        payload: { options: this.PAYLOADS.importToPay },
        component: MSelect,
        onChange: (_, value) => this.onChangePayment(_, value),
        className: "col-md-6",
      },
      {
        field: "paymentType",
        label: "Metodo di pagamento",
        payload: { options: this.PAYLOADS.paymentTypes },
        component: MSelect,
        className: "col-md-6",
      },
    ],
  ];

  FIELDS_LOGIN = [
    [
      {
        field: "emailLogin",
        label: "Email",
        component: MInput,
        type: "email",
        className: "col-md-6",
      },
      {
        field: "passwordLogin",
        label: "Password",
        component: MInput,
        type: "password",
        className: "col-md-6",
      },
    ],
  ];

  FIELDS_REGISTER = [
    [
      {
        field: "name",
        label: "Nome",
        component: MInput,
        autoComplete: "off",
        className: "col-md-6",
      },
      {
        field: "surname",
        label: "Cognome",
        component: MInput,
        className: "col-md-6",
      },
    ],
    [
      {
        field: "email",
        label: "Email",
        component: MInput,
        type: "email",
        className: "col-md-6",
      },
      {
        field: "emailRepeat",
        label: "Ripeti Email",
        component: MInput,
        type: "email",
        className: "col-md-6",
      },
    ],
    [
      {
        field: "password",
        label: "password a libera scelta",
        component: MInput,
        type: "password",
        className: "col-md-6",
      },
      {
        field: "passwordRepeat",
        label: "Ripeti Password",
        component: MInput,
        type: "password",
        className: "col-md-6",
      },
    ],
  ];

  FIELDS_REGISTER = [
    [
      {
        field: "name",
        label: "Nome",
        component: MInput,
        autoComplete: "off",
        className: "col-md-6",
      },
      {
        field: "surname",
        label: "Cognome",
        component: MInput,
        className: "col-md-6",
      },
    ],
    [
      {
        field: "email",
        label: "Email",
        component: MInput,
        type: "email",
        className: "col-md-6",
      },
      {
        field: "emailRepeat",
        label: "Ripeti Email",
        component: MInput,
        type: "email",
        className: "col-md-6",
      },
    ],
    [
      {
        field: "password",
        label: "password a libera scelta",
        component: MInput,
        type: "password",
        className: "col-md-6",
      },
      {
        field: "passwordRepeat",
        label: "Ripeti Password",
        component: MInput,
        type: "password",
        className: "col-md-6",
      },
    ],
  ];

  FIELDS_ADD = [
    {
      field: "hearAboutUs",
      label: "Come sei venuto a conoscenza dei nostri corsi?",
      payload: { options: this.PAYLOADS.heardAboutUs },
      component: MSelect,
      className: "col-md-6",
    },
  ];

  ERROR_MESSAGES = {
    importToPay: "Importo non valido!",
    paymentType: "Specificare la modalità di pagamento",
    name: "Inserire un Nome  valido",
    surname: "Inserire un Cognome valido",
    emailLogin: `L'Email non è valida`,
    passwordLogin: "Specificare la tua password di accesso",
    email: `L'Email non è valida`,
    emailRepeat: "Le due email non coincidono",
    password:
      "La Password non è valida (lunghezza almeno 6 caratteri, almeno una lettera maiuscola e almeno un numero)",
    passwordRepeat: "Le due password non coincidono",
    hearAboutUs: "Devi selezionare una opzione.",
  };

  validation = {
    importToPay: (value) => {
      return CourseHelper.isUnicamillus(this.props.courseCode)
        ? value >= 16
        : value >= 0;
    },
    paymentType: (value) => value !== "",
    name: (value) => value.length > 2,
    surname: (value) => value.length > 3,
    emailLogin: (value) => /\S+@\S+\.\S+/.test(value),
    passwordLogin: (value) => value.length > 5,
    email: (value) => /\S+@\S+\.\S+/.test(value),
    emailRepeat: (value, { email }) => email && value === email.value,
    password: (value) => /(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/.test(value),
    passwordRepeat: (value, { password }) => password && value === password.value,
    hearAboutUs: (value) => value !== "" && value !== "0",
  };

  PLUGIN = {
    CS24: FormCS24,
    ENG: FormENG,
    RCCFUALL: FormRCCFUALL,
    CV: FormCV,
    ATA: FormATA,
    INFO: FormINFO,
    UCSU: FormUCSU,
    USCUCOMBO: FormUCSUCombo,
    RCSU: FormRCSU,
    RSCUCOMBO: FormRCSUCombo,    
    RC30CFU: FormRC30CFU,
    UM30CFU: FormUM30CFU,
    CM30CFU: FormCM30CFU,
    TFA: FormTFA,
    TFAOrdinario: FormTFAOrdinario,
  };

  componentDidMount() {
    this.loadRemote(this.props.courseCode);
  }

  loadRemote(courseCode) {
    const isUnicamillus = CourseHelper.isUnicamillus(courseCode);
    const isReggioBonifico = CourseHelper.isReggioBonifico(courseCode);
    const isReggio = CourseHelper.isReggio(courseCode);
    const isTchaikovsky = CourseHelper.isTchaikovsky(courseCode);

    const currentAA = CourseHelper.getCurrentAA(courseCode);

    enrollmentAdminService
      .examSession(courseCode)
      .then(({ data }) => {
        const { payload } = data;

        this.setState({
          idExamSession: payload.idExamSession,
          courseCode,
          isUnicamillus,
          isTchaikovsky,
          isReggio,
          isReggioBonifico,
        });
      })
      .catch((error) => {
        this.setState({ loadingError: true });
      });

    enrollmentAdminService
      .importsToPay(courseCode)
      .then(({ data }) => {
        const { payload } = data;
        this.PAYLOADS.importToPay.length = 0;
        this.PAYLOADS.importToPay.push({
          label: "Seleziona importo",
          value: "-1",
        });
        this.PAYLOADS.importToPay.push(...payload);

        let importToPay = [];
        importToPay.push({ label: "Seleziona importo", value: "-1" });
        importToPay.push(...payload);

        this.setState({
          loading: false,
          PAYLOADS: {
            importToPay,
          },
        });
      })
      .catch((error) => {
        this.setState({ loadingError: true });
        this.setState({ loading: false, courseCode });
      });

    if (this.props.isLoggedIn) {
      enrollmentService
        .myEnrollment(courseCode, currentAA)
        .then(({ data }) => {
          if (data.payload.enrollment) {
            this.setState({ enrollment: data.payload.enrollment });
          }
          this.setState({ loading: false, courseCode });
        })
        .catch((error) => {
          if (!networkErrorHelper.is404(error)) {
            this.setState({ loadingError: true });
          }

          this.setState({ loading: false, courseCode });
        });
    }
    // verify userCourseAbilitation

    if (this.props.isLoggedIn) {
      const user = getCurrentUser();
      this.setState({ userId: user.id });

      enrollmentService
        .checkEnrollAbilitation(courseCode, user.id)
        .then(({ data }) => {
          if (data.payload.enrollAbilitation.courseCode === courseCode) {
            this.setState({ skipByEnrollAbilitation: true });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  toggle = (id) => {
    this.setState({
      ...this.defaultState,
      [`collapseOpen${id}`]: !this.state[`collapseOpen${id}`],
    });
  };

  validateApplyButton(officeCode = "") {
    //--- reset active promo on every keyup ---
    this.PAYLOADS.importToPay.length = 0;

    this.PAYLOADS.importToPay.push(...this.state.PAYLOADS.importToPay);
    this.setState({
      promoAmount: 0,
      importToPay: -1,
    });

    //-----------------------------------------

    const btnApply = document.getElementById("applyPromoBtn");
    officeCode === ""
      ? btnApply.classList.add("d-none")
      : btnApply.classList.remove("d-none");
  } //validateApplyButton

  applyPromo(officeCode = "") {
    const filter = {
      officeCode,
      courseCode: this.state.courseCode,
    };

    this.setState({ loading: true });

    adminService
      .verifyPromotionalCode(filter)
      .then((result) => {
        const { amount, onlyReferred, isProvider, skipFirstRate } =
          result.data.payload.promo;

        parseFloat(amount) > 0
          ? this.setState({ promoAmount: amount })
          : this.setState({ promoAmount: 0 });

        /** */
        if (skipFirstRate) {
          //--- update price list ---
          const imports = this.state.PAYLOADS.importToPay.map((item, idx) => {
            let newItem = { label: item.label, value: item.value };
            if (idx === 2) {
              newItem.label =
                item.label.replace(item.value, (0).toFixed(2)) +
                " (iscriviti adesso e paga dopo)";
              newItem.value = "0";
            }

            return newItem;
          });

          this.PAYLOADS.importToPay.length = 0;
          this.PAYLOADS.importToPay.push(...imports);
        } else if (!onlyReferred) {
          //--- update price list ---
          const imports = this.state.PAYLOADS.importToPay.map((item, idx) => {
            let newItem = { label: item.label, value: item.value };
            // idx ===0 identifica la "Soluzione Unica"
            if (newItem.value - amount >= 0 && idx === 1) {
              newItem.label = item.label.replace(
                item.value,
                (item.value - amount).toFixed(2)
              );
              newItem.value = (item.value - amount).toFixed(2);
            }

            return newItem;
          });

          this.PAYLOADS.importToPay.length = 0;
          this.PAYLOADS.importToPay.push(...imports);
        }

        this.setState({
          loading: false,
          onlyReferred,
          isProvider,
          skipFirstRate,
        });

        //--- hide apply button ---
        document.getElementById("applyPromoBtn").classList.add("d-none");
      })
      .catch((error) => {
        this.PAYLOADS.importToPay.length = 0;
        this.PAYLOADS.importToPay.push(...this.state.PAYLOADS.importToPay);

        this.setState({ promoAmount: 0 });

        /** show right message errors */
        if (
          networkErrorHelper.is422(error) ||
          networkErrorHelper.is404(error)
        ) {
          // to handle is404 error from system
          const myError = {
            response: {
              data: {
                errors: [
                  {
                    path: "officeCode",
                    message: "codice riservato non valido",
                  },
                ],
              },
            },
          };
          const newState = invalidFieldsMapper(
            myError,
            this.state,
            this.ERROR_MESSAGES
          );

          this.setState({ ...newState });
        } else {
          networkErrorHelper.notify(error);
        }

        this.setState({
          loading: false,
          onlyReferred: false,
          isProvider: false,
          skipFirstRate: false,
        });
      });
  } //applyPromo

  onChangePayment = (_, value) => {
    let index = -1;

    try {
      index = this.PAYLOADS.importToPay.findIndex(
        (paring) => parseInt(paring.value) === parseInt(value)
      );
    } catch (e) {
      console.log(e);
    }

    this.setState({
      isPaymentReferred: index === 2 ? true : false,
    });

    const importToPay = { value: value };
    this.updateDetailPaymentBox(importToPay, this.state.paymentType);
    //
  };

  onChangePaymentType = (_, value) => {
    const paymentType = { value: value };
    this.updateDetailPaymentBox(this.state.importToPay, paymentType);
  };

  updateDetailPaymentBox(importToPay, paymentType) {
    if (importToPay && paymentType) {
      if (
        paymentType.value === PAYMENT_TYPES.CARTA_DEL_DOCENTE &&
        importToPay.value > 0
      ) {
        const importWithCartaDocente = importToPay.value - 16;
        this.setState({ showDetailPayment: true, importWithCartaDocente });
      } else {
        this.setState({ showDetailPayment: false, importWithCartaDocente: 0 });
      }
    } else {
      this.setState({ showDetailPayment: false, importWithCartaDocente: 0 });
    }
  }

  onAccept = (idAccept, checked) => {
    this.setState({ [idAccept]: checked });
  };

  async checkLogin() {
    let message = "";
    // verify login fields
    const isEmail = this.checkValidationField("emailLogin");
    const isPassword = this.checkValidationField("passwordLogin");

    // verify login data
    if (isEmail && isPassword) {
      const { emailLogin, passwordLogin } = this.state;
      const p = userService
        .checkLogin(emailLogin.value, passwordLogin.value)
        .then(({ data }) => {
          this.setState({
            toLogin: true,
            toRegister: false,
            userId: data.payload.userId,
          });
        })
        .catch((error) => {
          if (networkErrorHelper.is404(error)) {
            message =
              'I dati di login non sono validi. Se hai dimenticato la password <a target="_blank" href="/password-reset">clicca qui per reimpostarla</a>';
          } else {
            message =
              "Si è verificato un errore durante la verifica dell'account";
          }
        });

      await Promise.resolve(p);
    } else {
      message = "Inserire i dati evidenziati in rosso";
    }

    return message;
  }

  async checkCourseConstraints(email = "") {
    let message = null;
    const courseCode = this.props.courseCode;
    const currentAA = CourseHelper.getCurrentAA(courseCode);

    const p = userService
      .checkCourseConstraints(email, courseCode, currentAA)
      .then(({ data }) => {
        const { courseEnrolled } = data.payload;

        let multipleEnrollAllow = false;
        //let canEnroll = true;
        if (courseEnrolled.courseCount >= 2) {
          message =
            "Non può iscriversi a questo corso universitario in quest'anno accademico, in quanto già iscritto ad altri corsi universitari. Per maggiori info inviare e-mail a info@formazionedocenti.it.";
          //courseEnrolled.courseTitle;
          /* message +=
             '</b>. Per ulteriori informazioni contatta il <a target="_blank" href="/supporto-tecnico">supporto tecnico</a> ';
           canEnroll = false;*/
        }

        /** manage exepition multiple enroll: iscrizione al secondo corso universitario (NO 24CFU) */
        /*        const isCFU = CourseHelper.isCFU(courseCode);
          
                if (courseEnrolled.univCount === 1 && courseEnrolled.courseCount === 1 && !isCFU) {
                  multipleEnrollAllow = true;
                  canEnroll=false;
                  message += " Iscrizione non permessa.";
                }
        */
        this.setState({
          multipleEnrollAllow: multipleEnrollAllow,
          courseEnrolled,
          //  canEnroll
        });
      })
      .catch((error) => {
        console.log(error);
      });

    await Promise.resolve(p);

    return message;
  }

  async checkRegistration() {
    let message = "";

    // verify registratoin fields
    // verify login fields
    const isEmail = this.checkValidationField("email");
    const isPassword = this.checkValidationField("password");
    const isEmailRepeat = this.checkValidationField("emailRepeat");
    const isPasswordRepeat = this.checkValidationField("passwordRepeat");
    const name = this.checkValidationField("name");
    const surname = this.checkValidationField("surname");

    // verify login data
    if (
      isEmail &&
      isPassword &&
      isEmailRepeat &&
      isPasswordRepeat &&
      name &&
      surname
    ) {
      const { email } = this.state;

      const p = userService
        .checkEmail(email.value)
        .then(({ data }) => {
          message =
            "Esiste già un account con questa email. Prova a selezionare 'ho un account' ";
        })
        .catch((error) => {
          if (networkErrorHelper.is404(error)) {
            this.setState({ toRegister: true, toLogin: false });
          } else {
            message =
              "Si è verificato un errore durante la verifica dell'account.";
          }
        });

      await Promise.resolve(p);
    } else {
      message = "Inserire i dati evidenziati in rosso";
    }

    return message;
  }

  renderPlugIn() {
    if (this.props.plugin) {
      if (this.PLUGIN[this.props.plugin] !== undefined) {
        const { promoAmount } = this.state;

        const Component = this.PLUGIN[this.props.plugin];
        if (Component) {
          return (
            <Component
              ref={this._pluginRef}
              promoAmount={promoAmount}
              onChangeImport={this.onChangeImport}
              onChangeCourseCode={this.onChangeCourseCode}
              courseCode={this.state.courseCode}
              pluginConfig={this.props.pluginConfig}
              hasMasterPromo={this.props.hasMasterPromo}
            />
          );
        }
      }
    }
  }

  onChangeCourseCode = (courseCode) => {
    // reload the form
    this.setState({ loading: true });
    this.loadRemote(courseCode);
  };

  onChangeImport = (payload) => {
    this.PAYLOADS.importToPay.length = 0;
    this.PAYLOADS.importToPay.push({ label: "Seleziona importo", value: "-1" });
    this.PAYLOADS.importToPay.push(...payload);

    let importToPay = [];
    importToPay.push({ label: "Seleziona importo", value: "-1" });
    importToPay.push(...payload);

    this.setState({
      PAYLOADS: {
        importToPay,
      },
    });
  };

  async checkForms() {
    // verify if accept conditions
    const { accept1, accept2, collapseOpen1, collapseOpen2 } = this.state;
    let alertMessages = [];

    await this.checkLogin();

    const importToPay = this.checkValidationField("importToPay");
    const paymentType = this.checkValidationField("paymentType");
    let plugin = true;
    if (this.props.plugin && this._pluginRef && this._pluginRef.current) {
      plugin = this._pluginRef.current.checkValidation();
    }

    if (!importToPay || !paymentType || !plugin) {
      alertMessages.push("Inserire i dati evidenziati in rosso");
    }

    if (this.props.enrollManifesto && !accept1) {
      alertMessages.push(
        "è necessario dichiarare di aver preso visione del bando e di accettarne le condizioni"
      );
    }
    if (!accept2) {
      alertMessages.push(
        "è necessario dichiarare di aver preso visione dell'informativa Trattamento Dati Personali e di accettarne le condizioni"
      );
    }
    /*
        if (!canEnroll){
          alertMessages.push(
            "Iscrizione al corso non permessa. "
          );
        }
    */
    if (!this.props.isLoggedIn) {
      if (collapseOpen1) {
        const message = await this.checkLogin();
        if (message) {
          alertMessages.push(message);
        } else {
          if (!this.state.skipByEnrollAbilitation) {
            const message = await this.checkCourseConstraints(
              this.state.emailLogin.value
            );
            if (message) {
              alertMessages.push(message);
            }
          }
        }
      } else if (collapseOpen2) {
        const message = await this.checkRegistration();
        if (message) {
          alertMessages.push(message);
        }
      } else {
        alertMessages.push(
          "Specificare se hai già un account o vuoi registrarti"
        );
      }
    } else {
      // user is logged
      if (!this.state.skipByEnrollAbilitation) {
        const message = await this.checkCourseConstraints();
        if (message) {
          alertMessages.push(message);
        }
      }
    }

    this.setState({ alertMessages });
    return alertMessages.length > 0 ? false : true;
  }

  async submit() {
    this.checkValidation();
    const checkForms = await this.checkForms();

    if (checkForms) {
      const payload = payloadBuilder(this.state);
      payload.toLogin = this.state.toLogin;
      payload.toRegister = this.state.toRegister;
      payload.courseCode = this.state.courseCode;
      payload.isPaymentReferred = this.state.isPaymentReferred;
      payload.idExamSession = this.state.idExamSession;
      payload.promoImport = this.state.promoAmount;

      if (this.props.plugin === "USCUCOMBO") {
        payload.plugin = "UCSU";
      } else if (this.props.plugin === "RSCUCOMBO") {
        payload.plugin = "RCSU";
      } else {
        payload.plugin = this.props.plugin;
      }
      payload.skipFirstRate = this.state.skipFirstRate || false;
      payload.statCookie = getStatCookie();

      if (this.props.plugin && this._pluginRef && this._pluginRef.current) {
        payload.pluginConfig = this._pluginRef.current.getValue();
      }

      // verify if bollo should de removed
      if (this.state.isUnicamillus) {
        if (this.state.showDetailPayment) {
          payload.importToPay = this.state.importWithCartaDocente;
        }
      }

      //--- START manage promoDescription ---
      if (parseFloat(this.state.promoAmount) > 0) {
        payload.promoDescription = `sconto di euro ${this.state.promoAmount} `;
        payload.promoDescription += payload.isPaymentReferred
          ? " da detrarre da ultima rata "
          : " detratto dal primo pagamento";
        payload.promoDescription += ` - codice ufficio ${this.state.officeCode.value}`;
      }

      if (
        parseFloat(this.state.promoAmount) === 0 &&
        parseFloat(payload.importToPay) === 0 &&
        this.state.skipFirstRate
      ) {
        payload.promoDescription =
          "registrati al corso e la prima rata la paghi dopo";
        payload.promoDescription += ` - codice ufficio ${this.state.officeCode.value}`;
        payload.isPaymentReferred = true;
      }
      //--- END manage promoDescription ---

      payload.currentAA = CourseHelper.getCurrentAA(this.state.courseCode);

      // send data to server
      enrollmentService
        .startEnrollment(payload)
        .then(({ data }) => {
          const { enrollment, user } = data.payload;

          // if ok and payment with credit card go to paymetn site
          if (
            enrollment.paymentType === PAYMENT_TYPES.CARTA_DI_CREDITO &&
            enrollment.importToPay > 0
          ) {
            // redirect to bank
            creditCardHelper.startPayment(
              user.email,
              enrollment.orderNumber,
              enrollment.importToPay,
              "userEnrollmentPayment",
              enrollment.company
            );
          }
          // else show result
          this.setState({ enrollment: enrollment, showEnrollResult: true });
        })
        .catch((error) => {
          const message = networkErrorHelper.getErrorMessage(error);

          // show error
          this.setState({ alertMessages: [message] });
        });
    }
  }

  /** */
  async sendEmailToMultipleEnroll(evt) {
    const objs = {
      userId: this.state.userId,
      courseCodeList: this.state.courseEnrolled.courseTitle,
      courseCode: this.state.courseCode,
    };

    enrollmentService
      .sendEmailToMultipleEnroll(objs)
      .then(({ data }) => {
        this.setState({
          multipleEnrollSendEmail: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  } //sendEmailToMultipleEnroll

  /** */
  renderEnrollResult(enrollment) {
    const { toRegister } = this.state;

    const link = ROUTES.MY_ENROLLMENT + "/" + enrollment.orderNumber;
    return (
      <BoxSuccess>
        <h6>Domanda di Iscrizione avviata correttamente </h6>
        {toRegister && (
          <p>
            Siamo lieti di averti con noi. Ti abbiamo inviato una email per
            confermare i tuoi dati e accedere alla tua area riservata.
          </p>
        )}
        {enrollment.paymentType === PAYMENT_TYPES.CARTA_DEL_DOCENTE && (
          <p>
            <a className="wrapper-link" href={link} target="_parent">
              {" "}
              Clicca qui per allegare il bonus carta docente e completare la tua
              domanda{" "}
            </a>
          </p>
        )}
        {enrollment.paymentType === PAYMENT_TYPES.MAV && (
          <p>
            <a className="wrapper-link" href={link} target="_parent">
              {" "}
              Clicca qui per completare la tua domanda{" "}
            </a>
          </p>
        )}
        {enrollment.paymentType === PAYMENT_TYPES.BONIFICO && (
          <p>
            Ti abbiamo inviato una email con gli estremi del bonifico. Per
            completare la domanda di iscrizione procedi con il pagamento tramite
            bonifico. A pagamento avvenuto entra nella tua area riservata per
            caricare la ricevuta di pagamento e completare i dati necessari per
            perfezionare la domanda di iscrizione.
            <a className="wrapper-link" href={link} target="_parent">
              {" "}
              Clicca qui per allegare il bonifico e completare la tua domanda{" "}
            </a>
          </p>
        )}
        {enrollment.paymentType === PAYMENT_TYPES.PAGOPA && (
          <a className="wrapper-link" href={link} target="_parent">
            {" "}
            Clicca qui per completare la tua domanda.{" "}
          </a>
        )}
      </BoxSuccess>
    );
  }

  renderPending(enrollment) {
    const route = ROUTES.MY_ENROLLMENT + "/" + enrollment.orderNumber;

    return (
      <BoxWarning>
        Hai già iniziato l'iscrizione a questo corso. Per completare
        <NavLink className="wrapper-link" to={route}>
          {" "}
          clicca qui
        </NavLink>
      </BoxWarning>
    );
  }

  renderShopping() {
    let renders = {};

    if (this.state.isUnicamillus) {
      renders = this.renderFields(
        [...this.FIELDS_ENROLL_UNICAMILLUS, this.FIELDS_ADD],
        this.state.defaultValues,
        true
      );
    } else if (this.state.isReggioBonifico) {
      renders = this.renderFields(
        [...this.FIELDS_ENROLL_REGGIO_BONIFICO, this.FIELDS_ADD],
        this.state.defaultValues,
        true
      );
    } else if (this.state.isReggio) {
      renders = this.renderFields(
        [...this.FIELDS_ENROLL_REGGIO, this.FIELDS_ADD],
        this.state.defaultValues,
        true
      );
    } else if (this.state.isTchaikovsky) {
      renders = this.renderFields(
        [...this.FIELDS_ENROLL_TCHAIKOVSKY, this.FIELDS_ADD],
        this.state.defaultValues,
        true
      );
    } else {
      renders = this.renderFields(
        [...this.FIELDS_ENROLL, this.FIELDS_ADD],
        this.state.defaultValues,
        true
      );
    }

    const { code } = renders;

    const login = this.renderFields(
      this.FIELDS_LOGIN,
      this.state.defaultValues,
      true
    );
    const register = this.renderFields(
      this.FIELDS_REGISTER,
      this.state.defaultValues,
      true
    );

    const {
      collapseOpen1,
      collapseOpen2,
      alertMessages,
      promoAmount,
      onlyReferred,
      courseCode,
      showDetailPayment,
      importWithCartaDocente,
    } = this.state;
    const { isLoggedIn } = this.props;

    return (
      <div className="section section-muted py-5">
        <Container>
          <Row className="text-center">
            <Col className="text-center">
              <h3>ISCRIZIONE ONLINE VERA</h3>
              <h5>Non occorre invio cartaceo</h5>
              <hr />
              <h6>
                {ReactHtmlParser(this.props.courseTitle)} ({courseCode})
              </h6>
            </Col>
          </Row>
          <Row>
            <Card teaser noWrapper className="no-after rounded">
              <CardBody>
                {this.props.plugin && <>{this.renderPlugIn()}</>}

                <div className="pt-4">{code}</div>
                {promoAmount > 0 && onlyReferred && (
                  <BoxSuccess>
                    sconto di <strong>{promoAmount}</strong> euro da detrarre
                    dall'ultima rata
                  </BoxSuccess>
                )}
                {promoAmount > 0 && !onlyReferred && (
                  <BoxSuccess>
                    sconto di <strong>{promoAmount}</strong> euro già detratto
                    dalla soluzione unica o da detrarre dall'ultima rata
                  </BoxSuccess>
                )}
                {showDetailPayment && (
                  <BoxSuccess>
                    <p>Dettagli del pagamento</p>
                    <hr />
                    <ul>
                      <li>
                        Carta del docente: tasse universitarie di{" "}
                        <b>{importWithCartaDocente} euro</b>
                      </li>
                      <li>
                        PagoPA: bollo virtuale di <b>16 euro</b>{" "}
                      </li>
                    </ul>
                  </BoxSuccess>
                )}
                <div>
                  <ul className="list-left">
                    <li>
                      <MCheckbox id="accept2" onChange={this.onAccept}>
                        Dichiaro di aver preso visione e di accettare{" "}
                        <a
                          className="link-dark"
                          href={ROUTES.INFORMATIVA}
                          rel="noopener noreferrer"
                          target="_blank">
                          l'Informativa Trattamento Dati Personali{" "}
                        </a>
                      </MCheckbox>
                    </li>
                    {this.props.enrollManifesto && (
                      <li>
                        <MCheckbox id="accept1" onChange={this.onAccept}>
                          {this.props.enrollManifesto === "#" ? (
                            <p>Dichiaro di accettare le condizioni del bando</p>
                          ) : (
                            <>
                              Dichiaro di aver preso visione quanto contenuto
                              nel
                              <a
                                className="link-dark"
                                href={this.props.enrollManifesto}
                                rel="noopener noreferrer"
                                target="_blank">
                                {" "}
                                Bando di partecipazione
                              </a>
                            </>
                          )}
                        </MCheckbox>
                      </li>
                    )}
                  </ul>
                </div>
                {!isLoggedIn && (
                  <>
                    <AccordionHeader
                      active={collapseOpen2}
                      onToggle={() => this.toggle(2)}>
                      <h5 className="text-dark card-title">
                        NON HO UN ACCOUNT - Registrati
                      </h5>
                    </AccordionHeader>
                    <AccordionBody active={collapseOpen2}>
                      {register.code}
                    </AccordionBody>
                    <Accordion>
                      <AccordionHeader
                        active={collapseOpen1}
                        onToggle={() => this.toggle(1)}>
                        <h5 className="text-dark card-title">
                          HO GIA' UN ACCOUNT - Accedi
                        </h5>
                      </AccordionHeader>
                      <AccordionBody active={collapseOpen1}>
                        {login.code}
                      </AccordionBody>
                    </Accordion>
                  </>
                )}
                {alertMessages.length > 0 && (
                  <>
                    <BoxDanger>
                      <ul>
                        {alertMessages.map((message, i) => (
                          <li key={i}> {ReactHtmlParser(message)}</li>
                        ))}
                      </ul>
                    </BoxDanger>
                  </>
                )}

                {this.state.multipleEnrollAllow &&
                !this.state.multipleEnrollSendEmail ? (
                  <>
                    <BoxWarning>
                      <ul>
                        <li>
                          Ci risulta già una iscrizione al seguente corso per
                          quest'anno accademico:{" "}
                          <b>{this.state.courseEnrolled.courseTitle}</b>
                        </li>
                        <li>
                          Per la contemporanea iscrizione a due Corsi
                          Universitari ai sensi della legge 12 aprile 2022, n.
                          33, La invitiamo a inviarci una richiesta di
                          informazioni (premendo il tasto Richiedi Informazioni)
                          e la contatteremo presto per le opportune
                          informazioni.
                        </li>
                      </ul>

                      <Button
                        color="primary bg-dark"
                        className="m-2"
                        onClick={(e) => this.sendEmailToMultipleEnroll(e)}>
                        Richiedi Informazioni
                      </Button>
                    </BoxWarning>
                  </>
                ) : (
                  ""
                )}

                {this.state.multipleEnrollSendEmail ? (
                  <>
                    <BoxSuccess>
                      <b>Richiesta Inviata.</b>
                      <div>
                        Abbiamo inviato la sua richiesta alla segreteria. La
                        contatteremo a breve per le opportune informazioni.
                      </div>
                    </BoxSuccess>
                  </>
                ) : (
                  ""
                )}

                {!this.state.multipleEnrollAllow && (
                  <Button
                    color="primary w-100 bg-dark mt-2 py-4"
                    onClick={() => this.submit()}>
                    PROCEDI
                  </Button>
                )}
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    );
  }

  render() {
    const { enrollment, loading, loadingError, showEnrollResult } = this.state;
    if (loading) return <></>;
    if (loadingError)
      return (
        <BoxDanger>
          Si è verificato un errore durante la lettura dei dati della domanda di
          iscrizione. Se il problema persiste la preghiamo di contattare il{" "}
          <SupportoTecnico />
        </BoxDanger>
      );

    return (
      <>
        {showEnrollResult ? (
          <>{this.renderEnrollResult(enrollment)}</>
        ) : (
          <>
            {enrollment ? (
              <>{this.renderPending(enrollment)}</>
            ) : (
              <>{this.renderShopping()}</>
            )}
          </>
        )}
      </>
    );
  }
}
